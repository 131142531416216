.clientgroup-table-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  font-size: 15px;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  box-sizing: border-box;
}

.clientgroup-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.clientgroup-row {
  width: 100%;
  justify-content: space-between;
  display: flex;
  outline: 1px solid black;
  overflow: hidden;
  align-items: center;
}

.clientgroup-row img {
  max-height: 80%;
  max-width: 80%;
  object-fit: contain;
}

.clientgroup-value-field {
  display: inline-block;
  align-items: center;
}
