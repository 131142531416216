.company-logo-container {
  position: absolute;
  left: 40px;
  top: 40px;
}

.company-logo {
  max-width: 400px;
  max-height: 140px;
  object-fit: contain;
}
