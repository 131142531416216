.special-days-left-column {
  display: flex;
  flex-direction: column;
  justify-content: relative;
  height: 100%;
}

.special-days-field-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
