@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.app {
  background-size: contain;
  background-position: center;
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
}

.scrollable-content {
  max-height: 100vh;
  /* Full viewport height */
  overflow-y: hidden;
}

.header-row {
  display: flex;
  flex-direction: row;
}

.table-row {
  display: flex;
}

.table-container {
  display: flex;
  flex-direction: column;
}

.table-header {
  display: flex;
}

.table-cell {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.columns-container {
  display: flex;
  flex-direction: column;
  height: 99.9vh;
  /* Ensures the container takes full viewport height */
}

.empty-row {
  flex-grow: 1;
  /* Makes the empty row fill up the rest of the space */
}

.columns-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 10vh;
}

.columns-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.columns-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-item {
  padding: 10px;
  flex: 1;
}

.events-column {
  padding: 10px;
  flex: 1;
}

.day-of-week {
  margin-right: 10px;
}

.day-header {
  display: flex;
  flex-direction: row;
}

.bookings {
  display: flex;
  /* Aligns children in a row */
  flex-direction: column;
  /* Stacks booking rows vertically */
}

.booking-row {
  display: flex;
  /* Aligns bookings in the same row */
  justify-content: space-between;
  /* Distributes space evenly between boxes */
  margin: 5px 0;
  /* Space between booking rows */
  width: 100%;
  /* Ensures row takes full width */
}

.booking-row.vertical {
  display: flex;
  justify-content: flex-start;
  /* Align items to the left */
  margin-bottom: 10px;
}

.booking-box {
  padding: 10px;
  flex: 1;
}

.invisible {
  visibility: hidden;
  /* Hides the element but still takes up space */
}

.event-details {
  display: flex;
  flex-direction: column;
}

/* Define keyframe animation for auto-scrolling */
@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.pagination-dots {
  position: absolute;
  justify-content: right;
  margin: 10px 10px;
  bottom: 10px;
  right: 10px;
}

.ellipsis-text-container {
  margin: 10px 40px;
  position: absolute;
  bottom: 10px;
  font-size: 20px;
}

.ellipsis-text-wrapper {
  position: absolute;
  bottom: 20px;
  font-style: italic;
  font-family: "Arial";
  display: contents;
}

.relative {
  position: relative;
}

.menu-of-the-week-container {
  display: flex;
  flex-direction: column;
}

.menu-of-the-week-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-content {
  flex: 1;
  display: flex;
}

.menu-booking-field {
  display: flex;
  width: 100%;
}

/* RoomAvailability.css */
.room-availability-container {
  gap: 1rem;
  padding: 1rem;
  height: 100vh;
  box-sizing: border-box;
}

.room-availability-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 0 20px #303030;
}

.room-availability-item.available {
  background-color: #e5e9e9;
}

.room-availability-item.occupied {
  background-color: #ff2f2f;
}

.room-status {
  margin: auto;
}

.status-text {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.details-text {
  font-size: 2rem;
  color: #555;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container p {
  margin-top: 10px;
  color: #333;
  font-size: 1.2em;
  animation: fadeIn 1.5s ease-in-out infinite;
}

@keyframes fadeIn {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
